import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Select, Input, Button, Row, Spin, Form, Col, DatePicker, Table, InputNumber, Checkbox } from "antd";
import { 
    applyReviewingBatchPaymentPlanAction, 
    getPaymentPlanConfigTemplateRequestAction, 
    selectPaymentPlanTemplateAction, 
    updateBatchPaymentPlanFormDataAction
} from "../../store/invoices/actions";
import { ApplicationState } from "../../store";
import { cloneDeep, forEach, get, isEmpty, isUndefined, some, sortBy, sum } from "lodash";
import { BatchPaymentPlanConfigModel } from "../../store/invoices/types";
import { getTranslatedText, limitOnlyNumber, pascalCaseToFlat } from "../../utils/commonFunctions";
import FormItem from "antd/lib/form/FormItem";
import { dateFormatDDMMMYYYYSpace, dateFormatDDMMYYYYSlash } from "../../constants/dateFormats";
import moment, { Moment } from "moment-timezone";
import { DynamicObject } from "../../utils/commonInterfaces";
import { naiveRound } from "../../utils/contentFunctions";

const { Option } = Select;

const START_TIMING = {
    CreateDate: 'Create date',
    RequestDate: 'Request date',
    OverdueDate: 'Overdue date'
};

const INSTALMENT_TYPE = {
    DATE_RANGE: 'DateRange',
    MONTHLY: 'Monthly',
    CUSTOM: 'Custom',
}

const FORM_FIELDS = {
    CONFIG_ID: 'ConfigId',
    NAME: 'Name',
    INSTALMENT_ITEMS: 'InstalmentItems',
    INSTALMENT_TYPE: 'InstalmentType',
    INSTALMENT_TYPE_SHOWING: 'InstalmentTypeShowing',
    SPLIT_INSTALMENT_DATE_RANGE: 'SplitInstalmentDateRange',
    SPECIFIC_PAYMENT_DAY: 'SpecificPaymentDate',
    NUMBER_OF_INSTALMENT: 'NumberOfInstalment',
    START_TIMING: 'StartTiming',
    FREQUENCY_GAP: 'FrequencyGap',
    START_DELAY: 'StartDelay',
    SKIP_CUSTOMER_NOTIFICATION: 'SkipCustomerNotification',
    IS_INDIVIDUAL_INVOICE: 'IsIndividualInvoice',
    CUTOFF_DATE: 'CutoffDate'
};

const INSTALMENT_ITEM_PROPERTIES = {
    ALOCATE_PERCENTAGE: 'AllocatePercentage',
    DUEDATE: 'InstalmentDueDate'
}

interface IProps {
    readonly form?: any;
    readonly customerLabel?: string | '';
    readonly paymentPlanTemplate?: BatchPaymentPlanConfigModel | undefined;
    readonly submitForm?: (formData: any) => void;
    readonly formatCurrency?: (amount: number) => string;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly handleNextStepFormClick: () => void;
    readonly handleBackStepFormClick: (isClosePanel?: boolean) => void;
    readonly handleFormValuesChange: (formData: any) => void;
};
interface ICombinedProps extends IProps {
    valueChanges?: any;
    setValueChanges: (valueChanges?: any) => void;
}

interface IInstalment {
    InstalmentNumber: number;
    AllocatePercentage: number;
    InstalmentDueDate: string | undefined;
}

interface IFormState {
    InitialFormData?: any | undefined;
    Instalments?: IInstalment[] | undefined;
    Error?: {
        HasError: boolean | undefined;
        ErrorMessage: string | undefined;
    }
}

const BulkPaymentPlanSelectTemplatePanel: React.FC<ICombinedProps> = ({
    form,
    customerLabel,
    formatCurrency,
    formatToParts,
    handleNextStepFormClick,
    handleBackStepFormClick,
    valueChanges
}) => {
    const dispatch = useDispatch();
    const batchPaymentPlanState = useSelector(
        (state: ApplicationState) => state.invoices.batchPaymentPlan
    );
    const paymentPlanConfigState = get(batchPaymentPlanState, 'paymentPlanConfigs');
    const selectedTemplateId = get(paymentPlanConfigState, 'selectedTemplateId');
    const selectedTemplate = (get(paymentPlanConfigState, 'data') || []).find(
        (selected: BatchPaymentPlanConfigModel) => selected.ConfigId === selectedTemplateId
    );
    const batchPaymentPlanFormData = get(batchPaymentPlanState, 'formData', {});

    const [formState, setFormState] = useState<IFormState | undefined>(undefined);
    const initialFormData = get(formState, 'InitialFormData', undefined);
    const { 
        getFieldDecorator, 
        getFieldValue,
        getFieldsValue, 
        getFieldsError,
        setFieldsValue
    } = form;
    const { RangePicker } = DatePicker;

    const instalmentTableColumns = [
        {
            title: 'Instalment',
            dataIndex: 'InstalmentNumber',
            width: '20%',
            render: (
                text: string, 
                record: IInstalment, 
                index: number
            ) => (
                <div style={{ flexGrow: 1 }} className="mr-5">
                    {record.InstalmentNumber}
                </div>
            )
        },
        {
            title: 'Allocate',
            dataIndex: 'AllocatePercentage',
            width: '40%',
            render: (
                text: string, 
                record: IInstalment, 
                index: number
            ) => (
                <FormItem>
                    {getFieldDecorator(`InstalmentItems[${index}].AllocatePercentage`, {
                        initialValue: record.AllocatePercentage,
                        rules: [
                            {
                                required: true,
                                message: getTranslatedText('Allocate percentage required!'),
                            }
                        ]
                    })(
                        <InputNumber
                            style={{ width: 200 }}
                            min={0}
                            max={100}
                        />
                    )}
                    <span className='ant-form-text'> %</span>
                </FormItem>
            )
        },
        {
            title: 'Instalment Due Date',
            dataIndex: 'InstalmentDueDate',
            width: '40%',
            render: (
                text: string, 
                record: IInstalment, 
                index: number
            ) => {
                const initialDueDateValue = isUndefined(record.InstalmentDueDate)
                    ? null
                    : moment(record.InstalmentDueDate, dateFormatDDMMYYYYSlash);
                return (
                    <>
                        {
                            initialFormData 
                            && initialFormData.InstalmentType !== INSTALMENT_TYPE.MONTHLY
                            && (
                                <FormItem>
                                    {getFieldDecorator(`InstalmentItems[${index}].InstalmentDueDate`, {
                                        initialValue: initialDueDateValue,
                                        rules: [
                                            {
                                                required: true,
                                                message: getTranslatedText('Instalment due date required!'),
                                            }
                                        ]
                                    })(
                                        <DatePicker 
                                            disabled={initialFormData.InstalmentType === INSTALMENT_TYPE.DATE_RANGE}
                                            format={dateFormatDDMMMYYYYSpace}
                                            placeholder={getTranslatedText("Instalment due date")}
                                        />
                                    )}
                                </FormItem>
                            )
                        }
                        {
                            initialFormData 
                            && initialFormData.InstalmentType === INSTALMENT_TYPE.MONTHLY
                            && (
                                <FormItem>
                                    {getFieldDecorator(`InstalmentItems[${index}].InstalmentDueDate`, {
                                        initialValue: `Calculated by invoice's ${pascalCaseToFlat((initialFormData.StartTiming || ''))}`
                                    })(
                                        <Input 
                                            style={{width: 200}}
                                            readOnly={true}
                                        />
                                    )}
                                </FormItem>
                            )
                        }
                    </>
                )
            }
        }
    ]

    const convertConfigurationToFormState = (): IFormState => {
        const splitInstalmentDateRange = (selectedTemplate.SplitInstalmentDateRange || []).map(
            (stringDate: string) => moment(stringDate)
        );
        const instalments: IInstalment[] = Array.from({ length: selectedTemplate.Instalments || 0 }, (_, i: Number) => i).map(
            (_, index: number) => ({
                InstalmentNumber: index + 1,
                AllocatePercentage: selectedTemplate.PayPercent
                    ? selectedTemplate.PayPercent[index] || 0
                    : 0,
                InstalmentDueDate: selectedTemplate.ScheduleDates
                    ? selectedTemplate.ScheduleDates[index] || undefined
                    : undefined
            })
        );

        const formState: IFormState = {
            InitialFormData: {
                [FORM_FIELDS.CONFIG_ID]: selectedTemplate.ConfigId,
                [FORM_FIELDS.NAME]: selectedTemplate.Name,
                [FORM_FIELDS.INSTALMENT_TYPE]: selectedTemplate.InstalmentType,
                [FORM_FIELDS.INSTALMENT_TYPE_SHOWING]: pascalCaseToFlat(selectedTemplate.InstalmentType),
                [FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE]: splitInstalmentDateRange,
                [FORM_FIELDS.SPECIFIC_PAYMENT_DAY]: selectedTemplate.SpecificPaymentDate,
                [FORM_FIELDS.NUMBER_OF_INSTALMENT]: selectedTemplate.Instalments,
                [FORM_FIELDS.START_TIMING]: selectedTemplate.StartTiming,
                [FORM_FIELDS.FREQUENCY_GAP]: selectedTemplate.FrequencyGap,
                [FORM_FIELDS.START_DELAY]: selectedTemplate.StartDelay,
                [FORM_FIELDS.CUTOFF_DATE]: selectedTemplate.CutoffDate
            },
            Instalments: instalments,
            Error: {
                HasError: false,
                ErrorMessage: undefined
            }
        };

        return formState;
    };

    const convertFormStateToConfiguration = (formData: any): BatchPaymentPlanConfigModel => {
        const payPercent: number[] = [];
        const scheduleDates: string [] = [];
        const instalmentItems = get(formData, FORM_FIELDS.INSTALMENT_ITEMS, []);
        if (!isEmpty(instalmentItems)) {
            instalmentItems.forEach(
                (instalmentItem: any) => {
                    if (!isUndefined(instalmentItem) && !isEmpty(instalmentItem)) {
                        payPercent.push(instalmentItem.AllocatePercentage);
                        const dueDate = !isUndefined(instalmentItem.InstalmentDueDate) && moment.isMoment(instalmentItem.InstalmentDueDate)
                            ? instalmentItem.InstalmentDueDate.format(dateFormatDDMMYYYYSlash)
                            : undefined;
                        if (!isUndefined(dueDate)) {
                            scheduleDates.push(dueDate);
                        }
                    }
                }
            );
        }

        const splitInstalmentDateRange: string[] = [];
        get(formData, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE, []).forEach(
            (date: any) => {
                if (moment.isMoment(date)) {
                    splitInstalmentDateRange.push(date.toISOString())
                }
            }
        )
        const template: BatchPaymentPlanConfigModel = {
            ConfigId: formData[FORM_FIELDS.CONFIG_ID],
            InstalmentType: formData[FORM_FIELDS.INSTALMENT_TYPE],
            Instalments: formData[FORM_FIELDS.NUMBER_OF_INSTALMENT],
            ScheduleDates: scheduleDates,
            PayPercent: payPercent,
            SplitInstalmentDateRange: splitInstalmentDateRange,
            Name: formData[FORM_FIELDS.NAME],
            CutoffDate: formData[FORM_FIELDS.CUTOFF_DATE],
            StartDelay: formData[FORM_FIELDS.START_DELAY],
            FrequencyGap: formData[FORM_FIELDS.FREQUENCY_GAP],
            SpecificPaymentDate: formData[FORM_FIELDS.SPECIFIC_PAYMENT_DAY],
            StartTiming: formData[FORM_FIELDS.START_TIMING]
        };

        return template;
    }

    const populateCustomDayChildrenOptions = () => {
        return Array.from({ length: 31 }, (_, i) => i + 1).map(
            (i: number) => (
                <Option key={i} value={i}>
                    {i}
                </Option>
            )
        );
    }

    const handleTemplateChange = (value: string) => {
        dispatch(selectPaymentPlanTemplateAction(value));
    };

    const isFormHasError = (errors: any): boolean => {
        return Object.values(errors).some((value: any) => {
            if (value && typeof value === "object") {
              return isFormHasError(value);
            }
            return Boolean(value);
          });
    };

    const getInstalmentsTotalAllocate = (): number => {
        let total: number = 0;
        const { InstalmentItems } = getFieldsValue();
        const validInstalments = InstalmentItems.filter((instalment: any) => !isEmpty(instalment));
        total = sum(validInstalments.map((instalment: any) => instalment.AllocatePercentage));
        
        return total;
    }

    const validateForm = (): { HasError: boolean, ErrorMessage: string | undefined } => {
        const newFormError : { HasError: boolean, ErrorMessage: string | undefined } = {
            HasError: false,
            ErrorMessage: undefined
        };
        
        const errors = getFieldsError();
        if (!isUndefined(errors) && !isEmpty(errors)) {
            if (isFormHasError(errors)) {
                newFormError.HasError = true;
            }
        }
        if (get(getFieldsValue(), 'InstalmentItems')) {
            if (getInstalmentsTotalAllocate() !== 100) {
                newFormError.HasError = true;
                newFormError.ErrorMessage = getTranslatedText('The required total payment percentage has not been met.');
            }
        }

        return newFormError;
    };

    const populateInstalments = (): IInstalment[] => {
        const {
            NumberOfInstalment,
            SplitInstalmentDateRange,
            InstalmentItems
        } = getFieldsValue();
        let instalments = (InstalmentItems || []).map(
            (instalmentItem: any, index: number) => ({
                InstalmentNumber: index + 1,
                AllocatePercentage: instalmentItem.AllocatePercentage,
                InstalmentDueDate: moment.isMoment(instalmentItem.InstalmentDueDate)
                    ? instalmentItem.InstalmentDueDate.format(dateFormatDDMMYYYYSlash)
                    : instalmentItem.InstalmentDueDate
            })
        );

        const changedProperties: string[] = [
            FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE,
            FORM_FIELDS.NUMBER_OF_INSTALMENT
        ];

        if (changedProperties.includes(Object.keys(valueChanges)[0])) {
            instalments = [];
            let startDate: Date | undefined = undefined;
            let endDate: Date | undefined = undefined;
            let dateDiff: number | undefined = undefined;
            let interval: number | undefined = undefined;

            if (!isUndefined(SplitInstalmentDateRange) && !isEmpty(SplitInstalmentDateRange)) {
                if (moment.isMoment(SplitInstalmentDateRange[0])) {
                    startDate = SplitInstalmentDateRange[0].toDate();
                }
                if (moment.isMoment(SplitInstalmentDateRange[1])) {
                    endDate = SplitInstalmentDateRange[1].toDate();
                }

                if (!isUndefined(startDate) && !isUndefined(endDate)) {
                    dateDiff = endDate.getTime() - startDate.getTime();
                    interval = dateDiff / NumberOfInstalment;
                }
            }

            for(let i = 1; i <= NumberOfInstalment; i++) {
                instalments.push({
                    InstalmentNumber: i,
                    AllocatePercentage: 0,
                    InstalmentDueDate: !isUndefined(dateDiff) && !isUndefined(startDate) && !isUndefined(interval)
                        ? moment(new Date(startDate.getTime() + i * interval)).format(dateFormatDDMMYYYYSlash)
                        : undefined
                })
            }
        }

        return instalments;
    };

    const populateFormState = () => {
        const newFormError = validateForm();
        const instalments = populateInstalments();
        setFormState({
            ...formState,
            Instalments: instalments,
            Error: newFormError
        });
    };

    const handleFormSubmit = () => {
        const formData = form.getFieldsValue();
        const instalmentItems = get(formData, FORM_FIELDS.INSTALMENT_ITEMS, []);
        if (!isEmpty(instalmentItems)) {
            formData[FORM_FIELDS.INSTALMENT_ITEMS] = instalmentItems.sort(
                (a: any, b: any) => {
                    if (moment.isMoment(a.InstalmentDueDate) && moment.isMoment(b.InstalmentDueDate)) {
                        return a.InstalmentDueDate.valueOf() - b.InstalmentDueDate.valueOf();
                    }
                    return 0;
                }
            )
        }
        const newConfiguration = convertFormStateToConfiguration(formData);

        dispatch(updateBatchPaymentPlanFormDataAction({
            ...batchPaymentPlanFormData,
            paymentPlanConfig: { ...newConfiguration },
            skipCustomerNotification: get(formData, FORM_FIELDS.SKIP_CUSTOMER_NOTIFICATION),
            isIndividualInvoice: get(formData, FORM_FIELDS.IS_INDIVIDUAL_INVOICE)
        }));
        dispatch(applyReviewingBatchPaymentPlanAction(formData));

        handleNextStepFormClick();
    };

    useEffect(() => {
        let isUnmounted = false;
        if (!isUnmounted) {
            const newFormError = validateForm();
            if (newFormError.HasError) {
                setFormState({
                    ...formState,
                    Error: newFormError
                });
            }
        }

        return () => {
            isUnmounted = true;
        }
    }, [get(formState, 'Instalments', [])]);

    useEffect(() => {
        let isUnmounted = false;
        if (!isUnmounted) {
            if (isUndefined(selectedTemplate)) return;
            
            setFormState((convertConfigurationToFormState()));
        }

        return () => {
            isUnmounted = true;
        }
    }, [selectedTemplate]);

    useEffect(() => {
        let isUnmounted = false;
        if (!isUnmounted && !isUndefined(valueChanges) && !isEmpty(valueChanges)) {
            populateFormState();
        }

        return () => {
            isUnmounted = true;
        }
    }, [valueChanges]);

    useEffect(() => {
        dispatch(getPaymentPlanConfigTemplateRequestAction())
    }, []);

    return (
        <Spin spinning={paymentPlanConfigState.loading} tip={getTranslatedText('Fetching Payment Plan Templates...')}>
            <Row>
                <Select
                    value={selectedTemplateId}
                    placeholder="Select a template"
                    onChange={handleTemplateChange}
                    style={{ width: "100%", marginBottom: "20px" }}
                >
                    {
                        paymentPlanConfigState.data.map((config: BatchPaymentPlanConfigModel) => (
                            <Option 
                                key={config.ConfigId}
                                value={config.ConfigId}
                            >
                                { config.Name }
                            </Option>
                        ))
                    }
                </Select>
                {
                    initialFormData 
                    && (
                        <Form 
                            key={selectedTemplateId}
                            labelAlign='left' 
                            className='form-inline-mb-0' 
                            labelCol={{ span: 7 }}
                        >
                            <Col span={24}>
                                <h4 className='red mb-8'>Payment Schedule details: </h4>
                                <FormItem>
                                    {getFieldDecorator(FORM_FIELDS.CONFIG_ID, {
                                        initialValue: initialFormData.ConfigId
                                    })(
                                        <Input 
                                            type='hidden'
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator(FORM_FIELDS.NAME, {
                                        initialValue: initialFormData.Name
                                    })(
                                        <Input 
                                            type='hidden'
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator(FORM_FIELDS.CUTOFF_DATE, {
                                        initialValue: initialFormData.CutoffDate
                                    })(
                                        <Input 
                                            type='hidden'
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator(FORM_FIELDS.INSTALMENT_TYPE, {
                                        initialValue: initialFormData.InstalmentType
                                    })(
                                        <Input 
                                            type='hidden'
                                        />
                                    )}
                                </FormItem>
                                <FormItem label={getTranslatedText('Instalment type')}>
                                    {getFieldDecorator(FORM_FIELDS.INSTALMENT_TYPE_SHOWING, {
                                        initialValue: initialFormData.InstalmentTypeShowing,
                                        rules: [
                                            {
                                                required: true,
                                                message: getTranslatedText('Instalment type required!'),
                                            }
                                        ]
                                    })(
                                        <Input 
                                            style={{ width: 200 }}
                                            readOnly={true}
                                        />
                                    )}
                                </FormItem>
                                {
                                    initialFormData.InstalmentType === INSTALMENT_TYPE.DATE_RANGE 
                                    && (
                                        <FormItem label={getTranslatedText('Instalment periods')}>
                                            {getFieldDecorator(FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE, {
                                                initialValue: initialFormData.SplitInstalmentDateRange,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: getTranslatedText('Instalment periods required!'),
                                                    }
                                                ]
                                            })(<RangePicker
                                                format={dateFormatDDMMMYYYYSpace}
                                                onChange={() => {}} 
                                                placeholder={[getTranslatedText("Start Date"), getTranslatedText("End Date")]}
                                                />)}
                                        </FormItem>
                                    )
                                }
                                {
                                    initialFormData.InstalmentType === INSTALMENT_TYPE.MONTHLY
                                    && (
                                        <>
                                            <FormItem
                                                label={getTranslatedText('Start Timing')}
                                            >
                                                {getFieldDecorator(FORM_FIELDS.START_TIMING, {
                                                    initialValue: initialFormData.StartTiming,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText('Start timing required!'),
                                                        }
                                                    ]
                                                })(
                                                    <Select 
                                                        style={{width: 200}}
                                                    >
                                                        {
                                                            Object.entries(START_TIMING).map(
                                                                ([key, value]) => (
                                                                    <Option key={key} value={key}>
                                                                        {value}
                                                                    </Option>
                                                                )
                                                            )
                                                        }
                                                    </Select>
                                                )}
                                            </FormItem>
                                            <FormItem
                                                label={getTranslatedText('Frequency Gap')}
                                            >
                                                {getFieldDecorator(FORM_FIELDS.FREQUENCY_GAP, {
                                                    initialValue: initialFormData.FrequencyGap,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText('Frequency Gap required!'),
                                                        }
                                                    ]
                                                })(
                                                    <InputNumber 
                                                        style={{width: 200}}
                                                        min={1}
                                                        max={100}
                                                        onKeyDown={limitOnlyNumber(false)}
                                                    />
                                                )}
                                            </FormItem>
                                            <FormItem 
                                                label={getTranslatedText('Payment Day')}
                                            >
                                                {getFieldDecorator(FORM_FIELDS.SPECIFIC_PAYMENT_DAY, {
                                                    initialValue: initialFormData.SpecificPaymentDate,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText('Payment Day required!'),
                                                        }
                                                    ]
                                                })(
                                                    <Select 
                                                        showSearch 
                                                        style={{ width: 200 }}
                                                    >
                                                        {populateCustomDayChildrenOptions()}
                                                    </Select>
                                                )}
                                            </FormItem>
                                            <FormItem
                                                label={getTranslatedText('Start delay')}
                                            >
                                                {getFieldDecorator(FORM_FIELDS.START_DELAY, {
                                                    initialValue: initialFormData.StartDelay,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText('Start delay required!'),
                                                        }
                                                    ]
                                                })(
                                                    <InputNumber 
                                                        style={{width: 200}}
                                                        min={1}
                                                        max={12} //months
                                                        onKeyDown={limitOnlyNumber(false)}
                                                    />
                                                )}
                                            </FormItem>
                                        </>
                                    )
                                }
                                <FormItem label={getTranslatedText('Number of instalments')}>
                                    {getFieldDecorator(FORM_FIELDS.NUMBER_OF_INSTALMENT, {
                                        initialValue: initialFormData.NumberOfInstalment,
                                        rules: [
                                            {
                                                required: true,
                                                message: getTranslatedText('Number of instalments required!'),
                                            },
                                            {
                                                validator: (rule: any, value: any, callback: any) => {
                                                    if (value && (value < 2 || value > 30)) {
                                                        callback(getTranslatedText('The number of instalments should be between 2 and 30.'));
                                                        return;
                                                    } 
                                                    callback();
                                                }
                                            }
                                        ]
                                    })(
                                        <InputNumber
                                            min={2}
                                            max={30} 
                                            style={{ width: 200 }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <h4 className='red mt-8 mb-8'>Instament schedule configuration: </h4>
                                <Table
                                    className='app-pl-instalments-table'
                                    columns={instalmentTableColumns}
                                    dataSource={get(formState, 'Instalments', [])}
                                    pagination={false}
                                    bordered={true}
                                    rowKey={(record: IInstalment) => (`key_${record.InstalmentNumber}`)}
                                    footer={() => {
                                        const instalments: IInstalment[] = get(formState, 'Instalments', []);
                                        const totalPercentage = sum(instalments.map((instalment: IInstalment) => instalment.AllocatePercentage));
                                        const errorMessage = get(formState, 'Error.ErrorMessage', undefined);
                                        return (
                                            <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                                <span>{getTranslatedText('Total')}:</span>
                                                <span style={{ marginLeft: '8px' }}>
                                                    {
                                                        totalPercentage
                                                    } %
                                                </span>
                                                {
                                                    !isUndefined(errorMessage)
                                                    && (<span className='red'> {errorMessage}</span>)
                                                }
                                            </div>
                                        )
                                    }}
                                    locale={{ emptyText: getTranslatedText('No Data') }}
                                />
                            </Col>
                            <Col span={24}>
                                <FormItem>
                                        { getFieldDecorator(FORM_FIELDS.SKIP_CUSTOMER_NOTIFICATION, {
                                            initialValue: false,
                                            valuePropName: 'checked',
                                        })(
                                            <Checkbox>
                                                { `Skip ${customerLabel}s notification for this payment plan`}
                                            </Checkbox>
                                        )}
                                    </FormItem>
                                    <FormItem>
                                        { getFieldDecorator(FORM_FIELDS.IS_INDIVIDUAL_INVOICE, {
                                            initialValue: false,
                                            valuePropName: 'checked',
                                        })(
                                            <Checkbox>
                                                Create individual invoices
                                            </Checkbox>
                                        )}
                                    </FormItem>
                                </Col>
                        </Form>
                    )
                }
                
            </Row>

            <Row
                justify='end'
                gutter={[16, 16]}
            >
                <Col 
                    span={24} 
                    className='ta-right'
                >
                    <Button 
                        type='primary'
                        style={{ marginRight: '8px'}}
                        disabled={get(formState, 'Error.HasError') === true}
                        onClick={handleFormSubmit}
                    >
                        { getTranslatedText('Next') }
                    </Button>
                    <Button 
                        onClick={() => handleBackStepFormClick()} 
                        type='default'
                        style={{ marginRight: '8px'}}
                    >
                        { getTranslatedText('Back') }
                    </Button>
                    <Button 
                        onClick={() => handleBackStepFormClick(true)} 
                        type='default'
                    >
                        { getTranslatedText('Cancel') }
                    </Button>
                </Col>
                </Row>
        </Spin>
    );
};

const BulkPaymentPlanSelectTemplateFormWrapper: React.FC<any> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const MemorizedForm = useMemo(() => {
        return Form.create({
            name: 'bulk-payment-plan-panel-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(BulkPaymentPlanSelectTemplatePanel);
    }, []);

    return (
        <MemorizedForm 
            {...props}
            valueChanges={valueChanges}
            setValueChanges={setValueChanges} 
        />
    )
}
export default BulkPaymentPlanSelectTemplateFormWrapper;

